import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import { Link } from 'gatsby'

import pic01 from '../assets/images/factory-workers-white-lab-suits-face-masks-producing-tv-sets-green-assembly-line-1.png'

const AboutUs = (props) => (
  <Layout>
    <Helmet>
      <title>About Us - Kimitech</title>
      <meta name="description" content="About us page" />
    </Helmet>

    <div id="main">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>ABOUT US</h1>
          </header>
          <span className="image main">
            <img src={pic01} alt="" />
          </span>
          <p>
            Kimitech International aim to be Malaysia's premier testing
            laboratory. Providing accurate, high-quality testing and monitoring
            services to large corporate and walk-in customers. We are staffed by
            a team of qualified scientists and engineers who are highly
            experienced and dedicated to conducting analyses using
            state-of-the-art equipment and infrastructure.
          </p>
          <p>
            We specialists in chemical analysis, microbiological analysis, blood
            and urine testing, food and feed analysis, pharmaceutical and
            nutraceutical analysis, industrial hygiene monitoring, environmental
            monitoring, occupational health testing, training and research and
            development.
          </p>
          <p>
            We also provide product development, performance, application and
            consulting services. Consulting work ranges from property and
            performance reporting and evaluation to advanced concept and product
            design discussions of various materials.
          </p>
        </div>
      </section>
      <section id="two" className="vision-mission">
        <article>
          <h3>OUR VISION: </h3>
          <p>
            Continuously enhanced to become an industry leader in Asia. We
            believe our vision can be achieved through responsiveness, technical
            excellence, and integrated multidisciplinary We believe this is
            achieved through our approach and, most importantly, our warm and
            friendly service team.
          </p>
        </article>
        <article>
          <h3>OUR MISSION: </h3>
          <p>
            Kimitec International is dedicated to providing superior technology
            to make your laboratory testing as easy and efficient as possible.
          </p>
        </article>
      </section>
    </div>
  </Layout>
)

export default AboutUs
